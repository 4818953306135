<template>
  <div id="myApp">
    <HeroComponent />
    <DividerSection />
    <div id="services">
      <v-container class="pb-10 pt-10">
        <v-row class="intro pb-5 pt-10">
          <v-col>
            <h2 style="font-size: 2em">
              Professional Flooring Services in Myrtle Beach, South Carolina.
            </h2>
            <p>
              Our team of licensed professionals provides a stress free approach
              to flooring installation. Call us today and we will come out and
              give you a fair and accurate quote. Our prices are the best in
              town and our customer service is unmatched.
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" class="d-flex" align="center">
            <v-img
              class="left-img"
              aspect-ratio="1"
              src="../assets/flooringInstallation.jpg"
              alt="Legacy Flooring & Repair - Flooring Installation & Repair in Myrtle Beach, SC"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <h2 class="pb-4" style="font-size: 2em">
                  <span style="font-weight: 300">Legacy Flooring</span>
                  <br />
                  Services
                </h2>
                <p>
                  Choosing Legacy Flooring means you’ll work with experts in the
                  field. We have the necessary experience, having done the same
                  job hundreds of times before. And we also have all the
                  necessary tools to get the job done perfectly.
                </p>
                <p>
                  This is why we stress that you choose a trusted and licensed
                  professional over a handyman or novice. At Legacy Flooring,
                  you can be sure you are getting the professional service that
                  is needed for these projects to be done the right way, on
                  budget, and on time.
                </p>
                <v-btn large rounded href="#contact" color="primary">
                  Request a Quote
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <DividerSection />
    <div id="work">
      <v-container class="pt-10 pb-10">
        <h2>Examples of our work.</h2>
        <p>Take a look through some of our previous work!</p>
        <v-row>
          <v-col
            v-for="(image, n) in gallery"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-img
              :src="require('../assets/gallery/' + image.src)"
              :alt="image.alt"
              aspect-ratio="1"
              class="grey lighten-2"
            >
              <template #placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <DividerSection />
    <div id="contact">
      <v-container class="pb-10 pt-10">
        <v-row class="intro pb-8 pt-10">
          <v-col cols="12" md="6">
            <h1>Where to find us!</h1>
            <p>
              Legacy Flooring is proudly serving the Myrtle Beach, Surfside,
              Garden City, Murrells Inlet, and Conway areas. Use the form to
              contact us today!
            </p>
            <h4>Conway, South Carolina</h4>
            <p>
              Office: (541) 588-0519
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="custom-card mx-auto">
              <v-form
                ref="contactForm"
                v-model="valid"
                class="post-form"
                lazy-validation
              >
                <v-card-title>
                  <h2 class="pb-2">Contact Us</h2>
                </v-card-title>
                <v-card-subtitle>
                  <h3 class="thin">
                    Use the form below to claim your free quote.
                  </h3>
                </v-card-subtitle>
                <v-card-text>
                  <v-text-field
                    v-model="contactForm.name"
                    label="Name"
                    outlined
                    required
                    :rules="formRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="contactForm.email"
                    class="text-feild"
                    label="Email"
                    outlined
                    required
                    :rules="emailRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="contactForm.phone"
                    class="text-feild"
                    label="Phone"
                    type="number"
                    outlined
                    required
                    :rules="formRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="contactForm.sqft"
                    class="text-feild"
                    label="Square footage of home."
                    outlined
                  >
                  </v-text-field>
                  <v-textarea
                    v-model="contactForm.message"
                    outlined
                    class="text-field"
                    label="Message"
                  ></v-textarea>
                  <v-card v-if="sendSuccess" color="success" dark
                    ><h4 class="pa-3">Message sent succesfully!</h4></v-card
                  >
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    :disabled="!valid"
                    rounded
                    color="primary"
                    @click="submit"
                  >
                    Submit
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { db } from "../plugins/firebase.js";
import HeroComponent from "../components/HeroComponent.vue";
import DividerSection from "@/components/DividerSection.vue";
export default {
  components: {
    HeroComponent,
    DividerSection,
  },
  data() {
    return {
      sendSuccess: false,
      valid: true,
      formRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      gallery: [
        {
          src: "img1.jpeg",
          alt: "Carpet installation in Conway, South Carolina",
        },
        { src: "img2.jpeg", alt: "Vinyl instal in Conway, South Carolina" },
        {
          src: "img3.jpeg",
          alt: "Flooring services in Myrtle Beach, South Carolina",
        },
        {
          src: "img4.jpeg",
          alt:
            "Professional flooring installation in Myrtle Beach, South Carolina",
        },
        {
          src: "img5.jpeg",
          alt: "Carpet installation in Myrtle Beach, South Carolina",
        },
        {
          src: "img6.jpeg",
          alt: "Hardwood flooring installation in Myrtle Beach, South Carolina",
        },
        {
          src: "img7.jpeg",
          alt: "Professional flooring installation in Conway, South Carolina",
        },
        {
          src: "img8.jpeg",
          alt:
            "Kitchen remodel and flooring installation in Myrtle Beach, South Carolina",
        },
        {
          src: "img9.jpeg",
          alt: "Housing upgrades to flooring in Myrtle Beach, South Carolina",
        },
      ],
      contactForm: {
        name: null,
        email: null,
        phone: null,
        sqft: null,
        message: null,
      },
    };
  },
  methods: {
    async submit() {
      window.console.log("attempting to send...");
      window.console.log("is validated = " + this.$refs.contactForm.validate());
      if (this.$refs.contactForm.validate()) {
        try {
          await db.collection("contacts").add({
            createdOn: new Date(),
            contactForm: this.contactForm,
          });
          this.$refs.contactForm.reset();
          this.sendSuccess = true;
        } catch (err) {
          window.console.log(err);
        }
      }
    },
  },
  head() {
    return {
      meta: {
        name: "Legacy Flooring & Repair | Myrtle Beach Flooring Experts",
        description:
          "Transform your space with Legacy Flooring & Repair, your premier source for flooring installation, repair, and maintenance in Myrtle Beach, SC.",
      },
    };
  },
};
</script>

<style scoped>
.intro h5 {
  text-transform: uppercase;
}
.upper-border {
  border-top: solid #333;
}
.upper-border-white {
  border-top: solid #fff;
}
</style>
